<template>
  <v-container v-if="!loading">
    <v-row v-if="isChef">
      <v-col cols="12" lg="5" offset-lg="2">
        <chef-forward />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" lg="5" offset-lg="2">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>Notifications</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-btn to="/settings/notification">Go</v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Meal Preferences</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-btn to="/settings/meal-preference">Go</v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Last Call Preferences</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-btn to="/settings/lastcall">Go</v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApprovalMessage from '../profile/ApprovalMessage'
import ChefForward from '@/components/common/ChefForward'

import { mapGetters } from 'vuex'

/*
Don’t send me anything (aside from vital account emails such as password reset notifications)
*/
export default {
  components: {
    ApprovalMessage,
    ChefForward
  },
  computed: {
    ...mapGetters({
      isChef: 'isChef',
      user: 'user',
      loading: 'loading'
    })
  },
  mounted() {
    //this.$store.dispatch('getUserSettings').then(() => {})
  }
}
</script>
