<template>
  <v-layout row v-if="showApprovalPendingMessage">
    <v-flex xs12 sm6 v-if="chefApprovalStatus == 'pending'">
      <v-alert
        outlined
        type="error"
        color="red darken-2"
        :value="true"
        icon="error_outline"
      >
        You account is in pending approval status. For meanwhile, you can setup
        your profile, create your dish list. With pending status, your products
        will not visible in TakeIn's marketplace.
      </v-alert>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      chefApprovalStatus: 'chefApprovalStatus',
      isChef: 'isChef',
      user: 'user',
      validProfile: 'validProfile'
    }),
    showApprovalPendingMessage() {
      return (
        this.isChef &&
        this.user.emailVerified &&
        this.chefApprovalStatus !== 'approved'
      )
    }
  }
}
</script>
